import * as React   from "react";
import styled       from "@emotion/styled";

import { NumberedPoints }     from "../../../../components/landing/promo/social-equity/NumberedPoints"
import { AdditionalDetails }  from "../../../../components/landing/promo/social-equity/AdditionalDetails"

interface Props {}
const tabletBreakpoint = "900px";
const mobileBreakpoint = "600px";

const SocialSubscribePage:  React.FunctionComponent<Props> = props => {
    return (
        <LocalWrapper>
            <GradientContainer>
                <HeaderContainer>
                    <HeaderGif>
                        <BorderScratch src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/borders-png/border-scratch_black.png"/>
                        <img src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/gif/mel-jumping_jack-clear.gif"/>
                    </HeaderGif>
                    <HeaderText>
                        <MainTitle>
                        <MobileBreak/>
                            Fitplan’s
                            <MobileBreak/>{" "}
                            <span>Sweat Equity</span>
                            <MobileBreak/>{" "}Challenge
                        </MainTitle>
                        <MissionStatement>
                            It’s time to get rewarded for all the hard work you put in on the{" "}
                            <b>Fitplan app</b> (as if the exercise-induced endorphins weren’t enough)!
                            <br/><br/>
                            Complete 16 workouts from June 1st - June 30th and earn 25% off all <b>Pulp Culture</b>,{" "}
                            hard pressed juice products and be entered to win a <b>WHOOP</b> strap and{" "}
                            membership to track your workouts, sleep and recovery!
                        </MissionStatement>
                        <br/>
                    </HeaderText>
                </HeaderContainer>
                <br/>
                <BottomContainer>
                <LogoContainer>
                    <img src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/logos/FITPLAN_grey.png"/>
                    <img src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/logos/client_logo/whoop-logo.png"/>
                    <img src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/logos/client_logo/pulp-culture-logo.png"/>
                </LogoContainer>
                <br/>
                <Title>
                    How to enter
                </Title>
                <NumberedPoints/>
                <br/>
                <Title>
                    Additional Details
                </Title>
                <br/>
                <AdditionalDetails/>
                <br/><br/><br/>
                </BottomContainer>
            </GradientContainer>
        </LocalWrapper>
    );
};

const MobileBreak = styled.br`
    @media (min-width: ${mobileBreakpoint}){
        display: none;
    } 
`;
const HeaderContainer = styled.div`
    background-color: #292a2a;
    color: #fff;
    width: 100vw;
    margin: 0 auto;
    position: relative;
    height: 100vh;
    overflow: hidden;
    @media (min-width: ${mobileBreakpoint}){
        height: auto;
    }
`;

const HeaderGif = styled.div`
    text-align: center;
    img {
        height: 100vh;
        position: absolute;
        top: 0%;  
        left: 50%;
        transform: translate(-50%, -0%);
    }
    @media (min-width: ${mobileBreakpoint}){
        img{
            position: relative;
            margin: 0 auto;
            max-width: 600px;
            max-height: 338px;
            width: 100vw;
            height: calc(338/600 * 100vw);
            top: 0;  
            left: 0;
            transform: translate(-0%, -0%);
        }
    }
`;
const BorderScratch = styled.img`
    position: absolute;
    z-index: 1;
    top: 0;  
    left: 50%;
    transform: translate(-50%, -0%);
    width: 100%;
    height: 100%;
    @media (min-width: ${mobileBreakpoint}){
        display: none;
    }
`;
const HeaderText = styled.div`
    text-align: center;
    width: 100%;
    margin: 0 auto ;
    background-color: rgba(0,0,0,.5);
    height: 100vh;
    padding: 0;

    position: absolute;
    z-index: 10;
    p {
        max-width: 750px;
    }
    @media (min-width: ${mobileBreakpoint}){
        height: 331px;
        padding: 10px 0px 0px;
        top: 0;  
        left: 50%;
        transform: translate(-50%, -0%);
    }
`;
const MissionStatement = styled.p`
    text-align: center;
    margin: 0 auto;
    font-size: 20px;
    padding: 0 10px;
    position: absolute;
    bottom: 20px;
    max-width: 800px;
    @media (min-width: ${mobileBreakpoint}){
        position: relative;
        margin-top: 130px;
    }
`;
const GradientContainer = styled.div`
    box-shadow: inset 0 7px 9px -7px rgba(128,130,177,0.4);
    position: relative;
`;
const LocalWrapper = styled.div`
    font-family: Barlow;

    color:#1d222b;
    background: #fff;

    width: 100vw;
    height: 100vh;
    overflow: scroll;

    position: fixed;
`;

const Title = styled.h1`
    text-align: center;
    font-weight: 600;
    font-size: 8vw;
    width: 100%;
    text-transform:uppercase;
    @media (min-width: ${tabletBreakpoint}){
        font-weight: 800;
        font-size: 36px;
    }
`;
const MainTitle = styled(Title)`
    border: none;
    font-size: 11vw;
    span{
        font-weight: 300;
    }
    line-height: 10vw;
    @media (min-width: ${mobileBreakpoint}){
        padding-top: 10px; 
        line-height: 100%;
        font-size: 36px;
    }
    @media (min-width: ${tabletBreakpoint}){
        padding-top: 10px; 
        line-height: 100%;
        font-size: 44px;
    }

`;
const BottomContainer = styled.div`
    position: relative;
`;
const LogoContainer =styled.div`
    margin: auto;
    text-align: center;
    img {
        width: 30%;
        max-width: 200px;
        vertical-align: top;
        padding: 1.5%;
    }
`;

export default SocialSubscribePage;