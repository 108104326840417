import React, { Component }   from "react";
import styled       from "@emotion/styled";

type Props= {}
const numberContainerWidth = "360px"

export class NumberedPoints extends Component<Props>{
    render(){
        return (   
            <Container>
                <NumberedContainer>
                    <NumberSide>1</NumberSide>
                    <TextSide>
                        Submit the following information to < a href="mailto:challenges@fitplanapp.com">challenges@fitplanapp.com</a>:
                            <ul>
                                <li>
                                    Name
                                </li>
                                <li>
                                    Email
                                </li>
                                <li>
                                    Instagram Handle
                                </li>
                                <li>
                                    Which trainer you’re working out with
                                </li>
                            </ul>
                    </TextSide>
                    <NumberSide>2</NumberSide>
                    <TextSide>
                        Complete 16 workouts in the Fitplan app from June 1st - June 30th
                    </TextSide>
                    <NumberSide>3</NumberSide>
                    <TextSide>
                        Follow @WHOOP, @fitplan_app & @pulpculturejuice on Instagram
                    </TextSide>
                    <NumberSide>4</NumberSide>
                    <TextSide>
                        Share and tag @WHOOP, @fitplan_app & @pulpculturejuice in an Instagram story or post 
                    </TextSide>
                </NumberedContainer>     
            </Container>
        );
    }
};
const Container = styled.div`
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
`;
const InlineDiv = styled.div`
    text-align: center;
    margin: 0 auto;

    vertical-align: top;
    display: block;
    position: relative;
    @media (min-width: ${numberContainerWidth}) {
        display: inline-block;
    }
`;
const NumberedContainer = styled(InlineDiv)`
    width: 100vw;
    padding: 10px 0;

    @media (min-width: ${numberContainerWidth}) {
        width: ${numberContainerWidth};
        background-color: transparent;
        padding: 40px 0;
    }
`;
const NumberSide = styled(InlineDiv)`
    font-size: 48px;
    font-weight: 800;
    width: 60px;
    margin-top: -8px;
`;

const TextSide = styled(InlineDiv)`
    font-size: 20px;
    text-align: center;
    width: 80vw;
    margin: 0 10vw 20px;

    ul {
        max-width: 300px;
        margin: 0 auto;
    }
    li {
        text-align: left;
        font-size: 90%;
    }
    @media (min-width: ${numberContainerWidth}) {
        text-align: left;

        width: calc(${numberContainerWidth} - 60px);
        margin: 0 0 20px;
    }
`;