import React, { Component }   from "react";
import styled       from "@emotion/styled";

type Props= {}
const tabletBreakpoint = "900px";

export class AdditionalDetails extends Component<Props>{
    render(){
        return (   
            <Container>
                <div>
                    <img src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/icons/thin-circle/email.png"/>
                    <br/><br/>
                    <section>
                        Upon submission to <b>challenges@fitplanapp.com</b> you’ll receive a 25% off code for Pulp Culture
                    </section>
                </div>
                <div>
                    <img src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/icons/thin-circle/raffle.png"/>
                    <br/><br/>
                    <section>
                        After completing 16 Fitplan app workouts, you’ll be entered to win a WHOOP strap and 6 month membership
                    </section>
                </div>
                <div>
                    <img src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/icons/thin-circle/instagram.png"/>
                    <br/><br/>
                    <section>
                        WHOOP winners will be chosen randomly and announced via Instagram/email on July 6th
                    </section>
                </div>          
            </Container>
        );
    }
};

const Container = styled.div`
    text-align: center;
    max-width: 900px;
    margin: 0 auto;

    div{
        width: 33vw;
        max-width: 300px;
        margin: 0 auto;
        vertical-align: top;
        display: inline-block;
    }
    img {
        height: 20vw;
        width: 20vw;
        max-height: 120px;
        max-width: 120px;

        vertical-align: top;
    }
    section{
        width: calc(33vw - 20px);
        max-width: 280px;
        margin: 0 10px;
    }
    small {
        font-size: 80%;
    }
    @media (max-width: ${tabletBreakpoint}) {
        div{
            width: 300px;
            margin: 10px auto 30px;
            display: block;
        }
        section{
            width: 300px;
        }
    }
`;